import React from "react"
import { Box, Container, Button } from "@mui/material"
import { isMobile } from "react-device-detect"

// import firebase from "firebase/app"
// import "firebase/storage"
// import firebaseConfig from "../../firebase/firebaseConfig"

// firebase.initializeApp(firebaseConfig)

const Player = () => {
  const videoUrl =
    "https://firebasestorage.googleapis.com/v0/b/funki-809de.appspot.com/o/Funki%20Demo%20ver.2%20ENG_small.mp4?alt=media&token=3dc968a8-a37e-42f1-a9eb-cb3f432b6e44"
  return (
    <div style={{ position: "relative", paddingTop: "56.25%" }}>
      <video
        controls
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    // <div style={{ position: "relative", paddingTop: "56.25%" }}>
    //   <iframe
    //     src={videoUrl}
    //     style={{
    //       position: "absolute",
    //       top: 0,
    //       left: 0,
    //       width: "100%",
    //       height: "100%",
    //     }}
    //   />
    // </div>
  )
}

export const Video = (props) => {
  const t = props.data

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          padding: isMobile ? "1rem 0 3rem 0 " : "3rem 0 5rem 0",
          display: "grid",
          gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
          gridTemplateRows: "auto",
          gridGap: isMobile ? "1rem" : "5rem",
          alignItems: "center",
          gridAutoFlow: isMobile ? "dense" : "row",
        }}
      >
        {!isMobile ? (
          <>
            {/* <Box sx={{ display: "flex", justifyContent: "flex-end"}}> */}
            <Player />
            {/* </Box> */}
            <Box>
              <Box>
                <h1>{t.title}</h1>
                <p>{t ? t.paragraph : "Loading"}</p>
              </Box>
              <Box>
                <Button
                  sx={{ alignSelf: "flex-end", width: "50%" }}
                  href="/#/contact"
                  variant="contained"
                  color="primary"
                  onClick={scrollToTop}
                >
                  {t.buttonText}
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Box>
                <h1>{t.title}</h1>
                <p>{t ? t.paragraph : "Loading"}</p>
              </Box>
              <Box paddingY="1rem">
                <Button
                  sx={{ alignSelf: "flex-end", width: "100%" }}
                  href="/#/contact"
                  variant="contained"
                  color="primary"
                  onClick={scrollToTop}
                >
                  {t.buttonText}
                </Button>
              </Box>
            </Box>
            <Player />
          </>
        )}
      </Box>
      {/* <hr /> */}
    </Container>
  )
}
