import React from "react"
import { Intro } from "../components/Cooperate/Intro"
import { HowDoWeDo } from "../components/Cooperate/HowDoWeDo"

function About({data}) {
	return (
		<>
			<Intro data={data.cooperateIntro} />
			<HowDoWeDo data={data.cooperateHow} />
		</>
	)
}

export default About