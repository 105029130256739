import React, { useRef } from "react"
import { Header } from "../components/Home/Header"
import { Intro } from "../components/Home/Intro"
import { Video } from "../components/Home/Video"
import { FutureFunki } from "../components/Home/FutureFunki"
import { Awards } from "../components/Home/Awards"

function Home(props) {
    const componentRef = useRef(null)

    const scrollToComponent = () => {
        if (componentRef.current) {
            componentRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            })
        }
    }

    return (
        <>
            <Header data={props.data.Header} scrollToComponent={scrollToComponent} />
            <Intro data={props.data.Intro} forwardedRef={componentRef} />
            <Video data={props.data.Video} />
            {/* <FutureFunki data={props.data.FutureFunki} /> */}
            <Awards data={props.data.Awards} />
        </>
    )
}

export default Home
