import React from "react"
import { Container, Box } from "@mui/material"
import { isMobile } from "react-device-detect"
import cofounders from "../../img/About/mia_josse_1.jpg"

export const History = (props) => {
  const t = props.data

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          padding: isMobile ? "5rem 0 3rem 0 " : "5rem 0 0 0",
          display: "grid",
          gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
          gridTemplateRows: "auto",
          gridGap: isMobile ? "1rem" : "5rem",
          alignItems: "center",
        }}
      >
        <Box>
          <h1 id="section-title">{t.title}</h1>
          <p style={{ whiteSpace: "pre-line", verticalAlign: "bottom" }}>
            {t ? t.paragraph : "Loading"}
          </p>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <img src={cofounders} width={isMobile ? "100%" : "100%"} />
        </Box>
      </Box>
      {/* <hr /> */}
    </Container>
  )
}
