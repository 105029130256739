import React from "react"
import { Container, Box } from "@mui/material"
import img from "../../img/Cooperate/4_small.jpg"
import { isMobile } from "react-device-detect"

export const Intro = (props) => {
  const t = props.data

  return (
    <Container maxWidth="lg" sx={{ pt: "5rem" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
          alignItems: "center",
          gridGap: "2rem",
        }}
      >
        <Box>
          <h1>{t ? t.title : "Loading"}</h1>
          <p>{t ? t.description : "Loading"}</p>
        </Box>
        <Box pt={!isMobile && 6}>
          <img src={img} width={"100%"} />
        </Box>
      </Box>
    </Container>
  )
}
