import React from "react"
import { Container, Box } from "@mui/material"
import Slide from 'react-reveal'
import { isMobile } from "react-device-detect"


export const HowDoWeDo = (props) => {
	const t = props.data

	const stepsList = [
		{
			title: t.stepOneTitle,
			description: t.stepOneDescription
		},
		{
			title: t.stepTwoTitle,
			description: t.stepTwoDescription
		},
		{
			title: t.stepThreeTitle,
			description: t.stepThreeDescription
		},
	]

	return (
		<Container maxWidth="lg">
			<Box sx={{padding: isMobile ? "1rem 0 3rem 0 " :  "5rem 0 5rem 0"}}>
				<Box sx={{ display: "flex", justifyContent: "center", paddingBottom: "2rem" }}>
					<h1>{t.title}</h1>
				</Box>
				<Box sx={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr", gridGap: "3rem"  }}>
					{stepsList.map((step, i) => (
						<Slide key={i} left>
							<Box  sx={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
								<h5 style={{ fontSize: "20px", margin: "0" }}>{step.title}</h5>
								<p style={{ marginBottom: i == 0 && "0px" }}>{step.description}</p>
								{i == 0 && <a href="/#/contact" style={{ color: "black", textDecoration: "underline" }}>{t.stepOneContactUs}</a>}
							</Box>
						</Slide>
					))}
				</Box>
			</Box>
		</Container>
	)

}