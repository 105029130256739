import React from "react"
import { Box, Container, Button } from "@mui/material"
import { isMobile } from "react-device-detect"
import fub1 from "../../img/Home/fub1.jpeg"

import SDG3 from "../../img/SDG/E_WEB_03.png"
import SDG4 from "../../img/SDG/E_WEB_04.png"
import SDG10 from "../../img/SDG/E_WEB_10.png"
import SDG_logo from "../../img/SDG/SDG_Logo_2.png"

export const Intro = (props) => {
  const t = props.data

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <Container maxWidth="lg" id="intro">
      <Box
        ref={props.forwardedRef}
        sx={{
          padding: isMobile ? "5rem 0 0 0 " : "8rem 0 5rem 0",
          display: "grid",
          gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
          gridTemplateRows: "auto",
          gridGap: isMobile ? "1rem" : "5rem",
          alignItems: "center",
        }}
      >
        <Box>
          <h1 id="section-title">{t.title}</h1>
          <p>{t ? t.paragraph : "Loading"}</p>

          {/* <Button
            variant="outlined"
            href="/#/about"
            onClick={scrollToTop}
            sx={{
              width: isMobile ? "100%" : "50%",
              padding: "0 4rem",
              borderRadius: 0,
              bgcolor: "white",
              color: "black",
              border: "2px solid black",

              "&:hover": {
                bgcolor: "lightblue",
                color: "black",
                border: "2px solid black",
              },
            }}
          >
            {t.readMore}
          </Button> */}

          <Button
            sx={{ alignSelf: "flex-end", width: isMobile ? "100%" : "50%" }}
            href="/#/about"
            variant="contained"
            color="primary"
            onClick={scrollToTop}
          >
            {t.readMore}
          </Button>

          <SDG />
        </Box>

        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <img src={fub1} width={isMobile ? "100%" : "100%"} />
            <p>Foto: FUB</p>
          </Box>
        </>
      </Box>
      {/* <hr /> */}
    </Container>
  )
}

const SDG = () => {
  const images = [SDG3, SDG4, SDG10]
  return (
    <Box>
      <Box
        sx={{
          paddingTop: "2rem",
          display: "flex",
          justifyContent: isMobile ? "center" : "",
          alignItems: "flex-start",
        }}
      >
        <img src={SDG_logo} width="50%" />
      </Box>
      <Box
        sx={{
          display: "flex",
          //   justifyContent: "space-between",
          paddingTop: "1rem",
        }}
      >
        {images.map((pic, idx) => (
          <Box
            key={idx}
            sx={{
              display: "flex",
              justifyContent: isMobile ? "center" : "",
              alignItems: "flex-start",
            }}
          >
            <img src={pic} alt="Sustainable Goal" width="60%" />
          </Box>
        ))}
      </Box>
    </Box>
  )
}
