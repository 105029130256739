import React from "react"
import logo from "../../img/Home/funki-logo-red.png"
import { isMobile } from "react-device-detect"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { Box, Button } from "@mui/material"
import profileJoy from "../../img/Home/profile_lab_joy.jpg"

export const Header = (props) => {
  const t = props

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        position: "relative",
        backgroundColor: "black",
        overflow: "hidden",
      }}
    >
      <img
        src={profileJoy}
        alt="bild på instrument"
        style={{
          //   filter: isMobile ? "brigtness(70%)" : "brightness(60%)",
          filter: isMobile ? "brigtness(70%)" : "brightness(50%)",
          objectFit: "cover",
          objectPosition: isMobile ? "center" : "center",
          width: "100%",
          height: "100%",
        }}
      />

      <Box
        sx={{
          position: "absolute",
          top: "0%",
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: isMobile ? "100%" : "100%",
            // height: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            // backgroundColor: "green",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              paddingBottom: isMobile && "15rem",
            }}
          >
            <Box
              sx={{
                width: isMobile ? "50vw" : "30vw",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={logo} width="100%" />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "90%",
            }}
          >
            <h3 style={{ fontSize: !isMobile && "30px" }}>
              {t.data.paragraph}
            </h3>
            <Button onClick={props.scrollToComponent}>
              <KeyboardArrowDownIcon fontSize="large" sx={{ color: "white" }} />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
