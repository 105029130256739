import React from "react"
import { Container, Box } from "@mui/material"
import mia from "../../img/Team/mia_foretagarna.jpg"
import josefine from "../../img/Team/josefine.jpg"
import frej from "../../img/Team/frej_profile_pic.jpg"
import vidar from "../../img/Team/Vidar_profile.jpg"
import david from "../../img/Team/david_profile_pic.jpg"
import harshdeep from "../../img/Team/harshdeep_profile.jpg"
import niklas from "../../img/Team/niklas_profilepic.jpg"
import gustaf from "../../img/Team/gustaf_profile.jpg"

import { isMobile } from "react-device-detect"

const imgList = [mia, josefine, vidar, frej, gustaf, niklas, david, harshdeep]

export const Team = (props) => {
  const t = props.data
  const teamPix = t.members
  return (
    <Container maxWidth="lg" sx={{ paddingBottom: "5rem" }}>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "2rem",
            paddingTop: " 2rem",
          }}
        >
          <h1>{t.title}</h1>
        </Box>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: isMobile ? "1fr 1fr" : "1fr 1fr 1fr",
          gridGap: isMobile ? "3rem" : "5rem",
        }}
      >
        {teamPix.map((person, key) => {
          return (
            <Box key={person.name}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <img src={imgList[key]} width={"80%"} />
              </Box>
              <h3>{person.name}</h3>
              <hr />
              <h4 style={{ marginBottom: "0px" }}>{person.job}</h4>
              {person.email && (
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "5px",
                    fontStyle: "italic",
                  }}
                >
                  {person.email}
                </p>
              )}
            </Box>
          )
        })}
      </Box>
    </Container>
  )
}
