import React from "react"
import { Container, Box } from "@mui/material"
import pic1 from "../../img/About/poster2.jpg"
import pic2 from "../../img/About/Bild 1.jpg"
import pic3 from "../../img/About/3_small.jpg"
import { isMobile } from "react-device-detect"

export const Intro = (props) => {
  const t = props.data

  return (
    <Container maxWidth="lg" sx={{ pt: "5rem" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
          gridGap: "0",
          // padding: isMobile ? "1rem 0 3rem 0 " :  "5rem 0 10rem 0"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingRight: ["0", "0", "3rem"],
          }}
        >
          <h1>{t.title}</h1>
          <p style={{ whiteSpace: "pre-line", verticalAlign: "bottom" }}>
            {t.paragraph1}
          </p>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img src={pic1} width={"100%"} />
        </Box>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img src={pic3} width={"100%"} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingLeft: ["0", "0", "3rem"],
          }}
        >
          <h1>{t.title2}</h1>
          <p style={{ whiteSpace: "pre-line", verticalAlign: "bottom" }}>
            {t.paragraph2}
          </p>
        </Box>
      </Box>
    </Container>
  )
}
