import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"

import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import MenuIcon from "@mui/icons-material/Menu"
import logo from "../img/Home/funki-logo-red.png"
import sweLogo from "../img/icons/sweden.png"
import engLogo from "../img/icons/united-kingdom.png"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import InstagramIcon from "@mui/icons-material/Instagram"

import { Box, Drawer, ListItemButton } from "@mui/material"

function Navigation(props) {
  const [scrolledNavbar, setScrolledNavbar] = useState(false)
  const [visable, setVisable] = useState(true)
  const [open, setState] = useState(false)
  const location = useLocation()
  const isHomePage = location.pathname === "/"

  const [language, setLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem("language")
    return storedLanguage || "SVE"
  })

  const [languageText, setLanguageText] = useState(() => {
    const storedLanguage = localStorage.getItem("language")
    if (storedLanguage === "SVE") {
      return "ENG"
    } else {
      return "SVE"
    }
  })

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    //changes the function state according to the value of open
    setState(open)
  }

  const changeBackground = () => {
    const isHomepage = location.pathname === "/"
    const scrollY = window.scrollY

    // Check if on homepage and scrollY is less than 300
    if (isHomepage && scrollY < 300) {
      setScrolledNavbar(false)
    } else {
      setScrolledNavbar(true)
    }
  }

  useEffect(() => {
    changeBackground()
    window.addEventListener("scroll", changeBackground)
    return () => {
      window.removeEventListener("scroll", changeBackground)
    }
  }, [])

  const handleLanguageSwitch = () => {
    if (language === "SVE") {
      props.languageSwitcher("ENG")
      setLanguage("ENG")
      setLanguageText("SVE")
    }
    if (language === "ENG") {
      props.languageSwitcher("SVE")
      setLanguage("SVE")
      setLanguageText("ENG")
    }
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <AppBar
      position="fixed"
      color={scrolledNavbar || !isHomePage ? "default" : "transparent"}
      elevation={scrolledNavbar || !isHomePage ? 1 : 0}
    >
      <Toolbar>
        <a href="/">
          <img alt="logo" src={logo} width="60px" />
        </a>
        <div style={{ flexGrow: 1 }} />
        <Box
          component="div"
          sx={{
            display: {
              xs: "none",
              sm: "block",
            },
          }}
        >
          <Button
            color="inherit"
            href="/"
            sx={{
              p: { color: isHomePage ? (scrolledNavbar ? "" : "white") : "" },
              ":hover": { textDecoration: "underline", color: "black" },
            }}
          >
            {props.data ? props.data.home : ""}
          </Button>
          <Button
            color="inherit"
            href="/#/about"
            sx={{
              p: { color: isHomePage ? (scrolledNavbar ? "" : "white") : "" },
              ":hover": { textDecoration: "underline", color: "black" },
            }}
            onClick={scrollToTop}
          >
            {props.data ? props.data.about : ""}
          </Button>
          <Button
            color="inherit"
            href="/#/news"
            sx={{
              p: { color: isHomePage ? (scrolledNavbar ? "" : "white") : "" },
              ":hover": { textDecoration: "underline", color: "black" },
            }}
            onClick={scrollToTop}
          >
            {props.data ? props.data.news : ""}
          </Button>
          <Button
            color="inherit"
            href="/#/join-us"
            sx={{
              p: { color: isHomePage ? (scrolledNavbar ? "" : "white") : "" },
              ":hover": { textDecoration: "underline", color: "black" },
            }}
            onClick={scrollToTop}
          >
            {props.data ? props.data.samarbeta : ""}
          </Button>
          <Button
            color="inherit"
            href="/#/contact"
            sx={{
              p: { color: isHomePage ? (scrolledNavbar ? "" : "white") : "" },
              ":hover": { textDecoration: "underline", color: "black" },
            }}
            onClick={scrollToTop}
          >
            {props.data ? props.data.contact : ""}
          </Button>
          <Button
            href="https://www.linkedin.com/company/funki/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon
              fontSize="medium"
              style={{
                cursor: "pointer",
                color: isHomePage
                  ? scrolledNavbar
                    ? "black"
                    : "white"
                  : "black",
                padding: "0",
              }}
            />
          </Button>
          <Button
            href="https://www.instagram.com/funkiinstruments"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon
              fontSize="medium"
              style={{
                cursor: "pointer",
                color: isHomePage
                  ? scrolledNavbar
                    ? "black"
                    : "white"
                  : "black",
                padding: 0,
              }}
            />
          </Button>
          <Button
            color="inherit"
            onClick={() => handleLanguageSwitch()}
            sx={{
              p: { color: isHomePage ? (scrolledNavbar ? "" : "white") : "" },
              ":hover": { textDecoration: "underline", color: "black" },
            }}
          >
            {languageText}
            <img
              src={language === "SVE" ? engLogo : sweLogo}
              width="18px"
              style={{
                position: "relative",
                top: "-2px",
                padding: "0.5rem",
              }}
            />
          </Button>
        </Box>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          sx={{
            mr: 2,
            display: {
              xs: "block",
              sm: "none",
              color: isHomePage && !scrolledNavbar ? "white" : "black",
            },
          }}
        >
          <MenuIcon onClick={toggleDrawer(true)} />
        </IconButton>
        <Drawer
          anchor="right"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Box
            sx={{
              p: 2,
              height: 1,
              backgroundColor: "#e6e6e6",
            }}
          >
            <IconButton sx={{ mb: 2 }}>
              <CloseIcon onClick={toggleDrawer(false)} />
            </IconButton>
            <Box sx={{ mb: 2, display: "flex", flexDirection: "column" }}>
              <ListItemButton href="/" onClick={scrollToTop}>
                <h5>{props.data ? props.data.home : ""}</h5>
              </ListItemButton>
              <ListItemButton href="/#/about" onClick={scrollToTop}>
                <h5>{props.data ? props.data.about : ""}</h5>
              </ListItemButton>
              <ListItemButton href="/#/news" onClick={scrollToTop}>
                <h5>{props.data ? props.data.news : ""}</h5>
              </ListItemButton>
              <ListItemButton href="/#/join-us" onClick={scrollToTop}>
                <h5>{props.data ? props.data.samarbeta : ""}</h5>
              </ListItemButton>
              <ListItemButton href="/#/contact">
                <h5>{props.data ? props.data.contact : ""}</h5>
              </ListItemButton>
              <ListItemButton onClick={() => handleLanguageSwitch()}>
                <h5>{languageText}</h5>
                <img
                  src={language === "SVE" ? engLogo : sweLogo}
                  width="18px"
                  style={{
                    position: "relative",
                    top: "-2px",
                    padding: "0.5rem",
                  }}
                />
              </ListItemButton>
              <Button
                href="https://www.linkedin.com/company/funki/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon
                  fontSize="medium"
                  style={{ cursor: "pointer", color: "black", padding: "0" }}
                />
              </Button>
              <Button
                href="https://www.instagram.com/funkiinstruments"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon
                  fontSize="medium"
                  style={{ cursor: "pointer", color: "black", padding: 0 }}
                />
              </Button>
            </Box>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  )
}

export default Navigation
