import React from "react"
import { Container, Box } from "@mui/material"
import { isMobile } from "react-device-detect"
import kth from "../../img/Awards/kth-logo.png"
import reach from "../../img/Awards/react-for-change.png"
import sscp from "../../img/Awards/sscp.png"
import brewhouse from "../../img/Awards/brewhouse_logo.png"
import nime from "../../img/Awards/nime.png"
import quality from "../../img/Awards/Quality_Innovation_Award.png"
import siq from "../../img/Awards/SIQ.png"
import foretagarna from "../../img/Awards/foretagarna-logo.png"
import solna from "../../img/Awards/solna-logo.png"
import nordea from "../../img/Awards/nordea_logo.png"
import venture from "../../img/Awards/venture-cup-sverige-logo.png"

const Picture = ({ pic, description, width }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                width: "90%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: { width },
                    height: "5rem",
                }}
            >
                <img src={pic} width="100%" />
            </Box>
            <hr style={{ borderTop: "1px solid #9a9a9a", width: "70%" }} />
            <Box sx={{ display: "flex", height: "5rem" }}>
                <p
                    style={{
                        margin: "0.5rem 0 0 0",
                        fontSize: isMobile ? "14px" : "16px",
                    }}
                >
                    {description}
                </p>
            </Box>
        </Box>
    )
}

export const Awards = (props) => {
    const t = props.data
    return (
        <Container maxWidth="lg">
            <Box
                sx={{
                    padding: isMobile ? "1rem 0 3rem 0 " : "5rem 0 10rem 0",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <h1 style={{ padding: "1rem 0 1.5rem 0" }}>{t.title1}</h1>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        placeItems: "center",
                        flexDirection: "column",
                    }}
                >
                    {isMobile ? (
                        <MobileSupport t={t} />
                    ) : (
                        <Box
                            sx={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr",
                                gridGap: "1rem",
                                placeItems: "center",
                                pb: "2rem",
                            }}
                        >
                            <Picture pic={reach} description={t.reachForChange} width={"10rem"} />
                            <Picture pic={sscp} description={t.produktions} width={"10rem"} />
                            <Picture pic={kth} description={t.kth} width={"10rem"} />
                            <Picture pic={brewhouse} description={t.brewhouse} width={"10rem"} />
                        </Box>
                    )}
                </Box>

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <h1 style={{ padding: "1rem 0 3rem 0" }}>{t.title2}</h1>
                </Box>

                {isMobile ? (
                    <MobileAwards t={t} />
                ) : (
                    <>
                        <Box
                            sx={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr",
                                gridGap: "2rem",
                                placeItems: "center",
                                pb: "2rem",
                            }}
                        >
                            <Picture pic={nime} description={t.nime} width={"7rem"} />
                            <Picture pic={solna} description={t.solna} width={"7rem"} />
                            <Picture pic={siq} description={t.siqSwe} width={"8rem"} />
                            <Picture pic={quality} description={t.siq} width={"10rem"} />
                            <Picture pic={foretagarna} description={t.foretagarna} width={"10rem"} />
                            <Picture pic={nordea} description={t.nordea} width={"10rem"} />
                            <Picture pic={venture} description={t.venture} width={"5rem"} />
                        </Box>
                        {/* <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", paddingX: "3rem" }}>
							
						</Box> */}
                    </>
                )}
            </Box>
        </Container>
    )
}

const MobileSupport = ({ t }) => {
    return (
        <>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridGap: "1rem",
                    placeItems: "center",
                    pb: "2rem",
                }}
            ></Box>
            <Picture pic={kth} description={t.kth} width={"10rem"} />
            <Picture pic={reach} description={t.reachForChange} width={"10rem"} />
            <Picture pic={sscp} description={t.produktions} width={"10rem"} />
            <Picture pic={brewhouse} description={t.brewhouse} width={"10rem"} />
        </>
    )
}

const MobileAwards = ({ t }) => {
    return (
        <>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridGap: "2rem",
                    placeItems: "center",
                    pb: "2rem",
                }}
            >
                <Picture pic={nime} description={t.nime} width={"7rem"} />
                <Picture pic={solna} description={t.solna} width={"7rem"} />
                <Picture pic={siq} description={t.siqSwe} width={"8rem"} />
                <Picture pic={quality} description={t.siq} width={"10rem"} />
                <Picture pic={foretagarna} description={t.foretagarna} width={"10rem"} />
                <Picture pic={nordea} description={t.nordea} width={"10rem"} />
                <Picture pic={venture} description={t.venture} width={"5rem"} />
            </Box>
            {/* <Box sx={{ display: "flex", pt: "2rem", justifyContent: "center" }}>
			</Box> */}
        </>
    )
}
