import React, { useRef } from "react"
import { Box, Container, Button, Divider } from "@mui/material"
import { isMobile } from "react-device-detect"

const Intro = (props) => {
  const t = props.data

  return (
    <Container maxWidth="lg" id="intro">
      <Box sx={{ paddingTop: "5rem", paddingBottom: "1rem" }}>
        <h1 id="section-title" ref={props.ref}>
          {t.title}
        </h1>
        <p>{t ? t.paragraph : "Loading"}</p>
      </Box>
    </Container>
  )
}

export default Intro
