import React, { useState, useRef } from "react"
import emailjs from "emailjs-com"
import { TextField, Button } from "@material-ui/core"
import { Container, Box } from "@mui/material"
import { isMobile } from "react-device-detect"

const initialState = {
  name: "",
  email: "",
  message: "",
}

export const ContactForm = (props) => {
  const t = props.data
  const [{ name, email, message }, setState] = useState(initialState)
  const [hasSent, setHasSent] = useState(false)
  const [emptyFields, setEmptyFields] = useState(false)
  const [sentError, setSentError] = useState(false)

  const formRef = useRef(null)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()

    if (name.trim() === "" || email.trim() === "" || message.trim() === "") {
      setEmptyFields(true)
      return
    }

    setEmptyFields(false)
    console.log(name, email, message)
    emailjs
      .sendForm(
        "service_fw3nd3c",
        "template_i3b9skl",
        formRef.current,
        "MRNQedKj1ZQvPj27T"
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
          setHasSent(true)
        },
        (error) => {
          console.log(error.text)
          setSentError(true)
        }
      )
  }

  return (
    <Container maxWidth="sm" sx={{ pt: "2rem" }}>
      <Box
        sx={{
          padding: isMobile ? "5rem 0 0 0 " : "5rem 0 0 0",
        }}
      >
        <h1 id="section-title">{t.title}</h1>
        <form
          onSubmit={handleSubmit}
          ref={formRef}
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "100%",
            margin: "0 0",
          }}
        >
          <TextField
            label={t.name}
            name="name"
            value={name}
            onChange={handleChange}
            required
          />
          <TextField
            sx={{ marginBottom: 2 }}
            label={t.email}
            name="email"
            type="email"
            value={email}
            onChange={handleChange}
            required
          />
          <TextField
            sx={{ marginBottom: 2 }}
            label={t.message}
            name="message"
            multiline
            minRows={4}
            value={message}
            onChange={handleChange}
            required
          />
          {/* <Button
            sx={{ alignSelf: "flex-end" }}
            variant="contained"
            color="primary"
            type="submit"
            // disabled={!validation}
            // onClick={handleSubmit}
          >
            {t.send}
          </Button> */}
          {/* TODO: What is this why does the normal button mess up the Drawer menu */}
          <button
            type="submit"
            style={{
              backgroundColor: "#1976D2",
              borderRadius: "3px",
              color: "white",
              border: "none",
            }}
          >
            <h4 style={{ margin: "0.5rem", fontSize: "14px" }}>{t.send}</h4>
          </button>
          {emptyFields && <h4 style={{ color: "red" }}>{t.emptyFields}</h4>}
          {hasSent && <h4>{t.sentMessage}</h4>}
          {sentError && <h4 style={{ color: "red" }}>{t.sentError}</h4>}
        </form>
      </Box>
    </Container>
  )
}

export default ContactForm
