import React, { useRef } from "react"
import { Box, Container, Button, Divider, Card, CardActions, CardContent, CardMedia, Typography, CardActionArea } from "@mui/material"
import { isMobile } from "react-device-detect"
import mia from "../../img/News/mia.jpg"
import nordea from "../../img/Awards/nordea_logo.png"
import siqpic from "../../img/News/siqpic.jpeg"
import solna from "../../img/News/solna.jpg"
import kth from "../../img/News/kth.png"
import ingenjoren from "../../img/News/ingenjoren.png"
import seb from "../../img/News/seb.png"
import inissionWinners from "../../img/News/inission-winners.jpg"
import brewhouse from "../../img/Awards/brewhouse_logo_2.png"
const imgList = [mia, nordea, siqpic, solna, kth, ingenjoren, seb, inissionWinners, brewhouse]

const Cards = (props) => {
    const t = props.data
    const articles = t.articles

    return (
        <Container maxWidth="lg" id="intro">
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: isMobile ? "1fr" : "repeat(4, 1fr)",
                }}
                paddingBottom={"3rem"}
            >
                {articles.map((article, idx) => (
                    <Box
                        key={idx}
                        sx={{
                            padding: !isMobile && "1rem",
                            paddingBottom: isMobile && "1rem",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <ArticleCard article={article} t={t} />
                    </Box>
                ))}
            </Box>
        </Container>
    )
}

const ArticleCard = ({ article, t }) => {
    return (
        <Card
            sx={{
                width: isMobile ? "auto" : 250,
                backgroundColor: "default",
                height: "auto",
            }}
        >
            <CardActionArea href={article.url} target="_blank">
                <CardMedia component="img" alt={article.title} height={isMobile ? "auto" : 140} image={imgList[article.id]} />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {article.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {article.description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default Cards
