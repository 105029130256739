import React from "react"
import { Box, BottomNavigation  } from "@mui/material"

export const Footer = () => {
	return (
		<BottomNavigation
		  style={{
				width: '100%',
				backgroundColor: 'white', // Add your preferred background color
		  }}
		>
		  <Box>
				<h5>Copyright 2022-2023 Funki Instruments AB</h5>
		  </Box>
		</BottomNavigation>

	)
}