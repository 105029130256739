import React from "react"
import { Intro } from "../components/About/Intro"
import { HowDoesItWork } from "../components/About/HowDoesItWork"
import { History } from "../components/About/History"

function About({ data }) {
  return (
    <>
      <Intro data={data.aboutIntro} />
      <HowDoesItWork data={data.howDoesItWork} />
      <History data={data.History} />
    </>
  )
}

export default About
