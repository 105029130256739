import React from "react"
import { Box, Container } from "@mui/material"
import metronome from "../../icons/metronome.png"
import heart from "../../icons/heart.png"
import next from "../../icons/next-button.png"
import waves from "../../icons/sound-waves.png"
import { isMobile } from "react-device-detect"
import Slide from "react-reveal"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"

export const arrowStyles = {
  position: "absolute",
  top: "5rem",
  bottom: "auto",
  zIndex: 2,
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
}

const MobileFuture = ({ data, t }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: isMobile ? "100%" : "50%",
        }}
      >
        <h1>{t.title}</h1>
      </Box>
      <Box
        sx={{
          width: isMobile ? "100%" : "50%",
          ".carousel-root": {
            display: "flex",
            justifyContent: "center",
          },
        }}
      >
        <Carousel
          autoPlay={true}
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          renderArrowNext={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                style={{
                  ...arrowStyles,
                  right: "0rem",
                }}
              >
                <ArrowForwardIosIcon />
              </button>
            )
          }
          renderArrowPrev={(clickHandler, hasNext, label) =>
            hasNext && (
              <button
                type="button"
                onClick={clickHandler}
                title={label}
                style={{
                  ...arrowStyles,
                  left: "0rem",
                }}
              >
                <ArrowBackIosNewIcon />
              </button>
            )
          }
        >
          {data.map((feature, i) => (
            <Box
              key={i}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                pb: 3,
              }}
            >
              <img src={feature.pic} style={{ width: "20%" }} />
              <p style={{ width: "80%" }}>{feature.description}</p>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Box>
  )
}

export const HowDoesItWork = (props) => {
  const t = props.data

  const featureList = [
    {
      pic: metronome,
      description: t.feature1,
    },
    {
      pic: next,
      description: t.feature2,
    },
    {
      pic: heart,
      description: t.feature3,
    },
    {
      pic: waves,
      description: t.feature4,
    },
  ]

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          padding: isMobile ? "1rem 0 3rem 0 " : "5rem 0 10rem 0",
        }}
      >
        <MobileFuture data={featureList} t={t} />
      </Box>
      <hr />
    </Container>
    // <Container maxWidth="xl">
    //   <Box sx={{ padding: isMobile ? "1rem 0 1rem 0 " : "5rem 0 0 0" }}>
    //     <Box
    //       sx={{
    //         display: "flex",
    //         justifyContent: "center",
    //         paddingBottom: "2rem",
    //       }}
    //     >
    //       <h1>{t.title}</h1>
    //     </Box>
    //     <MobileFuture data={featureList} />
    //     {/* {isMobile ? <MobileFuture data={featureList} /> : <DesktopFeature data={featureList} />} */}
    //   </Box>
    // </Container>
  )
}

// const DesktopFeature = ({ data }) => {
// 	return (
// 	  <Box
// 		sx={{
// 		  display: "grid",
// 		  gridTemplateColumns: "1fr 1fr 1fr 1fr",
// 		  gridGap: "2rem",
// 		}}
// 	  >
// 		{data.map((feature, i) => (
// 		  <Slide key={i} left>
// 			<Box
// 			  sx={{
// 				display: "flex",
// 				flexDirection: "column",
// 				alignItems: "center",
// 				textAlign: "center",
// 			  }}
// 			>
// 			  <img src={feature.pic} width={"30%"} />
// 			  <p>{feature.description}</p>
// 			</Box>
// 		  </Slide>
// 		))}
// 	  </Box>
// 	)
//   }
