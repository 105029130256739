// index.js
import React, { useState, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import SWEJsonData from './data/data_SWE.json'
import ENGJsonData from './data/data_ENG.json'

const languageData = {
	SVE: SWEJsonData,
	ENG: ENGJsonData
}

const Root = () => {

	// Set the initial language to 'SVE' when entering the website
	const initialLanguage = 'SVE'

	const [language, setLanguage] = useState(() => {
		const storedLanguage = localStorage.getItem('language')
		return storedLanguage || initialLanguage
	})

	const languageSwitcher = (lang) => {
		setLanguage(lang)
	}

	useEffect(() => {
		// Save the language preference to local storage whenever it changes
		localStorage.setItem('language', language)
	}, [language])
  
	return (
	  <React.StrictMode>
			<App language={language} languageData={languageData} languageSwitcher={languageSwitcher} />
	  </React.StrictMode>
	)
}



ReactDOM.render(<Root />, document.getElementById('root'))


reportWebVitals()
