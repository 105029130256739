import React from "react"
import Intro from "../components/News/Intro"
import Cards from "../components/News/Cards"

function News({ data }) {
  return (
    <>
      <Intro data={data.News} />
      <Cards data={data.News} />
    </>
  )
}

export default News
