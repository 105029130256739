import React from "react"
import { ContactForm } from "../components/Contact/ContactForm"
import { Team } from "../components/About/Team"
import MailchimpForm from "../components/Contact/MailListForm"

function Contact({ data }) {
  return (
    <>
      <ContactForm data={data.Contact} />
      <Team data={data.Team} />
    </>
  )
}

export default Contact
