// App.js
import React, { useState, useEffect } from "react"
import "./App.css"
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { HashRouter as Router, Route, Routes } from "react-router-dom"
import Home from "./pages/Home"
import About from "./pages/About"
import Cooperate from "./pages/Cooperate"
import Contact from "./pages/Contact"
import Navigation from "./components/Navbar"
import { Footer } from "./components/Footer"
import News from "./pages/News"

function App({ language, languageData, languageSwitcher }) {
  const landingPageData = languageData[language]

  return (
    <Router>
      <Navigation
        data={landingPageData.Navigation}
        languageSwitcher={languageSwitcher}
      />
      <Routes>
        <Route path="/" element={<Home data={landingPageData} />} />
        <Route path="/about" element={<About data={landingPageData} />} />
        <Route path="/news" element={<News data={landingPageData} />} />
        <Route path="/join-us" element={<Cooperate data={landingPageData} />} />
        <Route path="/contact" element={<Contact data={landingPageData} />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default App
